import wwwwImg from '../../assets/img/www.png';
import educatorImg from '../../assets/img/tutoria.png';
import tutorImg from '../../assets/img/estudio.png';
import javiImg from '../../assets/img/info.jpg';
import knowledgeImg from '../../assets/img/conocimientos.png';
import dashboardImg from "../../assets/img/dashboard.png";

import { Roles } from '../../features/users/roles/roles';

export const sidebarItems = [
  {
    imgSrc: dashboardImg,
    alt: 'Imagen conocimiento',
    text: 'Estadísticas de tutorías',
    link: `/tutorias/estadisticas`,
    linkText: 'Ver dashboard',
    roles: [Roles.ADMIN],
  },
  {
    imgSrc: educatorImg,
    alt: 'Imagen educadores',
    text: 'Ven inscríbete en nuestros talleres disponibles',
    externalLink:
      'https://docs.google.com/spreadsheets/d/1F7xWgJUbuaZkcMfXUw7oFK_C_XxWTt_Urzfa6SjMf5A/edit#gid=1490852139',
    linkText: 'Ver calendario de talleres',
    roles: [Roles.STUDENT, Roles.WORKSHOP_STUDENT, Roles.TUTOR],
  },
  {
    imgSrc: tutorImg,
    alt: 'Imagen de usuario tipo tutor',
    text: '¿Te gustaría ayudar como tutor/a?',
    link: `/ser-tutor`,
    linkText: 'Inscríbete aquí',
    roles: [Roles.STUDENT, Roles.WORKSHOP_STUDENT],
  },
  {
    imgSrc: knowledgeImg,
    alt: 'Imagen conocimiento',
    text: '¿Tienes dudas en alguna materia? Revisa o pide tutorías desde acá',
    externalLink: `${process.env.REACT_APP_STUDENTS_APP_URL}/plataforma.php`,
    linkText: 'Mis tutorías',
    roles: [Roles.STUDENT, Roles.TUTOR],
  },
  {
    imgSrc: javiImg,
    alt: 'Imagen educadores',
    text: '¿Algún problema? ¿Nos necesitas?',
    externalLink: `https://api.whatsapp.com/send/?phone=573053897083&text&type=phone_number&app_absent=0`,
    linkText: 'Dale click aqui',
    roles: [Roles.STUDENT, Roles.WORKSHOP_STUDENT, Roles.STUDY_GROUP_TUTOR, Roles.TUTOR],
    cssClass: 'borderRadiusImg',
  },
  {
    imgSrc: knowledgeImg,
    alt: 'Imagen conocimiento',
    text: 'Gestión de tutorías',
    link: `/tutorias/lista`,
    linkText: 'Administrar',
    roles: [Roles.ADMIN, Roles.TUTOR],
  },
  {
    imgSrc: educatorImg,
    alt: 'Imagen educadores',
    text: 'Gestión de tutores',
    link: `/tutores`,
    linkText: 'Administrar',
    roles: [Roles.ADMIN],
  },
  {
    imgSrc: wwwwImg,
    alt: 'Imagen cohete',
    text: 'Somos la fundación que te acompaña',
    externalLink: 'https://fundacionantivirusparaladesercion.org/',
    linkText: 'Visita la página',
  },
];
