import { createSlice } from '@reduxjs/toolkit';
import { newTutorToStudentRating } from '../tutorToStudentRating/tutorToStudentRatingThunks';

const initialState = {
  isLoading: false,
  /** @type {string | null} */
  error: null,
  /** @type {any?} */
  data: null,
};

export const tutorToStudentRatingSlice = createSlice({
  name: 'tutorToStudentRating',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(newTutorToStudentRating.pending, (state, _action) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(newTutorToStudentRating.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
      })
      .addCase(newTutorToStudentRating.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      });
  },
});

export const TutorToStudentRatingSelector = (state) => state.tutorToStudentRating.data;
export const TutorToStudentRatingErrorSelector = (state) => state.tutorToStudentRating.error;
