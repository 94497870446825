import { createSlice } from '@reduxjs/toolkit';
import {
  fetchTutorsAvailableForStudyGroups,
  sendStudyGroupTutorRequest,
  registerOrUpdateTutor,
  updateTutor,
  fetchTutors,
  removeTutor,
  fetchAllTutorInfoById,
} from './tutorThunks';

const initialState = {
  isLoading: false,
  /** @type {string | null} */
  error: null,
  availableForStudyGroups: null,
  fullList: null,
  currentOne: null,
  sentRequest: null,
  /** @type {any?} */
  data: null,

  /** @type {boolean?} */
  successfullyUpdated: null,
  removedTutor: null,
};

export const tutorSlice = createSlice({
  name: 'tutor',
  initialState,
  reducers: {
    resetTutorState: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(fetchTutorsAvailableForStudyGroups.pending, (state, _action) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchTutorsAvailableForStudyGroups.fulfilled, (state, action) => {
        state.isLoading = false;
        state.availableForStudyGroups = action.payload;
      })
      .addCase(fetchTutorsAvailableForStudyGroups.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })

      .addCase(fetchTutors.pending, (state, _action) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchTutors.fulfilled, (state, action) => {
        state.isLoading = false;
        state.fullList = action.payload.data;
      })
      .addCase(fetchTutors.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })

      .addCase(fetchAllTutorInfoById.pending, (state, _action) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchAllTutorInfoById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.currentOne = action.payload.data;
      })
      .addCase(fetchAllTutorInfoById.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })

      .addCase(sendStudyGroupTutorRequest.pending, (state, _action) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(sendStudyGroupTutorRequest.fulfilled, (state, action) => {
        state.isLoading = false;
        state.sentRequest = action.payload;
      })
      .addCase(sendStudyGroupTutorRequest.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })

      .addCase(registerOrUpdateTutor.pending, (state, _action) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(registerOrUpdateTutor.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
      })
      .addCase(registerOrUpdateTutor.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })

      .addCase(updateTutor.pending, (state, _action) => {
        state.isLoading = true;
        state.error = null;
        state.successfullyUpdated = null;
      })
      .addCase(updateTutor.fulfilled, (state, action) => {
        state.isLoading = false;
        state.successfullyUpdated = !!action.payload.data;
      })
      .addCase(updateTutor.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
        state.successfullyUpdated = false;
      })

      .addCase(removeTutor.pending, (state, _action) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(removeTutor.fulfilled, (state, action) => {
        state.isLoading = false;
        state.removedTutor = action.payload.data;
      })
      .addCase(removeTutor.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      });
  },
});

export const { resetTutorState } = tutorSlice.actions;

export const tutorsSelector = (state) => state.tutor.fullList;
export const currentTutorSelector = (state) => state.tutor.currentOne;
export const tutorsAvailableForStudyGroupsSelector = (state) => state.tutor.availableForStudyGroups;
export const tutorSentRequestSelector = (state) => state.tutor.sentRequest;
export const tutorSelector = (state) => state.tutor.data;
export const isTutorUpdatedSelector = (state) => state.tutor.successfullyUpdated;
export const tutorErrorSelector = (state) => state.tutor.error;

export default tutorSlice.reducer;
